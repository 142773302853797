<template>
  <v-container fluid height="90vh" class="mt-8">
    <v-row align="center" justify="center">
      <v-col cols="10">
        <v-expansion-panels v-model="activeStorage" style="justify-content: flex-start">
          <v-expansion-panel
            style="border-radius: 8px; background-color: var(--v-background-base)"
            v-for="item in storage"
            :key="item.id"
            @click="activeRow = undefined"
          >
            <v-expansion-panel-header class="px-2" expand-icon="">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  color: #3a5676;
                "
                class="pl-8 pr-9"
              >
                <span
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #3a5676;
                  "
                >
                  <h2 style="display: inline">Lager {{ item.name }}</h2>
                  <v-icon v-if="activeStorage + 1 == item.name">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </span>
                <h2>Frei: {{ item.occupiedSpaces }} / {{ item.totalSpaces }}</h2>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="rowStoragePanel">
              <v-expansion-panels
                :disabled="activeStorage + 1 != item.name"
                accordion
                v-model="activeRow"
                style="justify-content: flex-start"
              >
                <NewStorageRow
                  :activeRow="activeRow"
                  :storage="item.name"
                  :rowData="row"
                  v-for="row in item.row_set"
                  :key="row.id"
                  @storeTire="storeTire"
                />
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <div class="fullScreenOverlay" v-if="loading">
      <div class="overlayBackground" style="opacity: 0.1"></div>
      <v-progress-circular
        indeterminate
        color="primary"
        class="overlayDisplay"
      ></v-progress-circular>
    </div>
    <v-snackbar
      v-model="snackbar"
      :multi-line="true"
      top
      color="primary"
      class="text-center"
      timeout="-1"
    >
      <br />
      Beim Lagern der Reifen ist ein Fehler aufgetreten. Bitte wählen Sie eine andere Reihe.

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Schließen </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import NewStorageRow from "@/components/Storage/NewStorageRow.vue";

export default {
  data() {
    return {
      storage: [],
      activeStorage: 0,
      activeRow: undefined,
      loading: false,
      snackbar: false,
    };
  },
  components: {
    NewStorageRow,
  },
  computed: {
    isMobile() {
      if (this.$vuetify.breakpoint.width < 1500) return true;
      return false;
    },
  },
  methods: {
    fetchData() {
      this.loading = true;

      axios
        .get(axios.defaults.baseURL + "storages/counted")
        .then((resp) => {
          this.storage = resp.data;
          console.log(resp.data);

          for (let j = 0; j < this.storage.length; j++) {
            this.storage[j].occupiedSpaces = 0;
            this.storage[j].totalSpaces = 0;
            for (let i = 0; i < this.storage[j].row_set.length; i++) {
              this.storage[j].occupiedSpaces += this.storage[j].row_set[
                i
              ].space_filled.count;
              this.storage[j].totalSpaces += this.storage[j].row_set[
                i
              ].space_filled.total_spaces;
            }
          }
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    setActiveRow(id) {
      if (this.activeRow != id) this.activeRow = id;
      else this.activeRow = undefined;
    },
    storeTire(rowID) {
      this.loading = true;
      setTimeout(() => {
        axios
          .get(axios.defaults.baseURL + "empty/space/by/row/?id=" + rowID)
          .then((resp) => {
            console.log(resp.data);
            let randomSpace = Math.floor(Math.random() * resp.data.length);

            let tireData = {
              id: this.$route.params.reifen,
              in_storage: true,
              Space: resp.data[randomSpace].id,
            };

            axios
              .patch(axios.defaults.baseURL + "tires/simple/" + tireData.id, tireData)
              .then(() => {
                this.loading = false;
                this.$router.push(
                  "/fahrzeug/" +
                    this.$route.params.kunde +
                    "/" +
                    this.$route.params.fahrzeug
                );
              })
              .catch(() => {
                this.loading = false;
                this.snackbar = true;
              });
          })
          .catch(() => {
            this.loading = false;
          });
      }, 1000);
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
