<template>
  <div id="termine">
    <v-container fill-height fluid class="innerCard mb-16">
      <v-row align="center" justify="center">
        <v-col :cols="colLimit">
          <v-card
            height="fit-content"
            class="ma-8 pa-8 pt-2"
            :class="{ 'mt-10': !singleLineHeader, 'mt-2': singleLineHeader }"
            text
          >
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Heute
                </v-btn>
                <v-chip>
                  <h3 v-if="focus">
                    {{ getWeekday(new Date(focus).getDay()) }}
                  </h3>
                  <h3 v-else>
                    {{ getWeekday(new Date().getDay()) }}
                  </h3>
                </v-chip>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-menu
                  class="py-0"
                  ref="dateSelector"
                  v-model="showDatePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <h3 v-if="focus" v-on="on" v-bind="attrs">
                      {{ focus.split("-")[2] }}
                    </h3>
                    <h3 v-else v-on="on" v-bind="attrs">
                      {{ new Date().getDate() }}
                    </h3>
                  </template>
                  <v-date-picker
                    :active-picker.sync="activeDatePicker"
                    locale="de-DE"
                    :first-day-of-week="1"
                    v-model="dateSelectorData"
                  ></v-date-picker>
                </v-menu>

                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar" style="display: flex">
                  <v-menu
                    class="py-0"
                    ref="monthSelector"
                    v-model="showMonthPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <h4 v-if="focus" v-bind="attrs" v-on="on" class="mr-2">
                        {{ months[focus.split("-")[1] - 1] }}
                      </h4>
                      <h4 v-else v-bind="attrs" v-on="on" class="mr-2">
                        {{ months[new Date().getMonth()] }}
                      </h4>
                    </template>
                    <v-date-picker
                      :active-picker.sync="activeMonthPicker"
                      locale="de-DE"
                      v-model="dateSelectorData"
                    ></v-date-picker>
                  </v-menu>

                  <v-menu
                    class="py-0"
                    ref="yearSelector"
                    v-model="showYearPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <h4 v-if="focus" v-bind="attrs" v-on="on">
                        {{ focus.split("-")[0] }}
                      </h4>
                      <h4 v-else v-bind="attrs" v-on="on">
                        {{ new Date().getFullYear() }}
                      </h4>
                    </template>
                    <v-date-picker
                      :active-picker.sync="activeYearPicker"
                      locale="de-DE"
                      v-model="dateSelectorData"
                    ></v-date-picker>
                  </v-menu>
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-col cols="auto" class="pa-0">
                  <v-combobox
                    :value="displayCategories"
                    :items="categories"
                    multiple
                    chips
                    close
                    @input="switchStageDisplay"
                    class="hideInputBottomSpace mb-0"
                  >
                    <template
                      v-slot:selection="{ attrs, item, parent, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        label
                        small
                      >
                        <span class="pr-2">
                          {{ item }}
                        </span>
                        <v-icon small @click="parent.selectItem(item)">
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>

                <v-spacer></v-spacer>
                <v-spacer></v-spacer>

                <v-btn
                  class="rounded-lg outlinedButton"
                  elevation="0"
                  @click="gotoMonths()"
                  ><v-icon>mdi-calendar-month</v-icon></v-btn
                >
              </v-toolbar>
            </v-sheet>

            <v-sheet>
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                type="category"
                :events="events"
                :event-color="getEventColor"
                :event-ripple="false"
                :first-interval="17"
                :interval-count="18"
                :interval-format="formatDateString"
                :interval-minutes="30"
                :interval-height="64"
                :hide-header="false"
                :category-show-all="true"
                @mousedown:event="startDrag"
                @mousedown:time="startTime"
                @mousemove:time="mouseMove"
                @mouseup:time="endDrag"
                @mouseleave.native="cancelDrag"
                @click:event="selectEvent"
                @mouseenter:time-category="selectCategory"
                @mouseleave:time-category="deselectCategory"
                :categories="displayCategories"
              >
                <template #event="{ event, timed }">
                  <div class="idDiv">{{ event.id }}</div>
                  <div class="urlDiv">{{ event.url }}</div>
                  <div class="px-2" style="padding-top: 2px">
                    <v-container class="pa-0 ma-0">
                      <v-row class="pa-0 ma-0">
                        <v-col cols="auto" class="pa-0 ma-0" v-if="event.vip">
                          <v-icon color="yellow">mdi-star</v-icon>
                        </v-col>
                        <v-col
                          v-if="event.customer != ' '"
                          cols="6"
                          class="pa-0 ma-0"
                        >
                          <h3
                            style="
                              font-size: large;
                              text-overflow: ellipsis;
                              overflow-x: hidden;
                            "
                          >
                            {{ event.customer }}
                          </h3>
                        </v-col>
                        <v-spacer v-if="event.customer != ' '" />
                        <v-col class="pa-0 ma-0" cols="auto">
                          <div>
                            <div class="v-event-draggable">
                              <strong height="20px">{{ event.name }}</strong>
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          v-if="event.annotation"
                          cols="auto"
                          class="pa-0 ma-0 pl-4"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="white"
                                >mdi-chat</v-icon
                              >
                            </template>
                            <span>{{ event.annotation }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0 ma-0">
                        <v-container
                          class="pa-0 ma-0"
                          fluid
                          style="font-size: medium"
                        >
                          <v-row
                            class="pa-0 ma-0"
                            style="display: flex; align-items: flex-start"
                          >
                            <v-col
                              class="pa-0 ma-0 mr-1 pb-2"
                              cols="auto"
                              v-for="(icon, index) in event.icons"
                              :key="index"
                            >
                              <div v-html="icon" class="svgIconSizer"></div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col
                              style="display: flex; align-items: center"
                              cols="auto"
                              class="pa-0 ma-0 mr-1"
                              v-if="event.status == 0"
                            >
                              <v-btn
                                color="white"
                                class="iconButton"
                                icon
                                text
                                @click="acceptWarning($event, event)"
                              >
                                <v-icon color="primary">mdi-check</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col
                              style="display: flex; align-items: flex-start"
                              cols="auto"
                              class="pa-0 ma-0 mr-1 ml-2"
                              v-if="event.status == 0"
                            >
                              <v-btn
                                class="iconButton"
                                icon
                                text
                                @click="rejectionWarning($event, event)"
                              >
                                <v-icon color="red">mdi-close</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-row>
                    </v-container>
                    <div
                      v-if="timed"
                      class="v-event-drag-bottom"
                      @mousedown.stop="extendBottom(event)"
                    ></div>
                  </div>
                </template>
              </v-calendar>
            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-overlay :value="overlay" :dark="false">
      <v-form ref="appForm">
        <v-btn
          @click="closeOverlay()"
          icon
          text
          class="overlayClose rounded-xl"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card class="pa-10">
          <v-container
            fluid
            class="overlayContainer"
            style="width: 40vw !important; height: fit-content"
          >
            <v-row>
              <v-col>
                <h2 class="text-center">Termin Buchen</h2>
              </v-col>
            </v-row>
            <hr class="my-2" />

            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0"> <h3>Termindaten:</h3> </v-col>
            </v-row>

            <v-row class="ma-0 pa-0" style="justify-content: space-between">
              <v-col class="ma-0 pa-0" :cols="inputWidth">
                <v-menu
                  class="py-0"
                  ref="overlayDateSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-container fluid class="ma-0 pa-0">
                      <v-row
                        class="ma-0 pa-0"
                        style="display: flex; align-items: center"
                      >
                        <v-col cols="2" class="ma-0 pa-0">
                          <v-icon
                            v-on="on"
                            class="pa-0 pr-3"
                            style="width: 30px; height: 30px"
                          >
                            mdi-calendar
                          </v-icon>
                        </v-col>
                        <v-col cols="10" class="ma-0 pa-0">
                          <v-text-field
                            :value="
                              getReadableDate(appointmentOverlayData.date)
                            "
                            readonly
                            v-bind="attrs"
                            label="Datum"
                            v-on="on"
                            required
                            :rules="dateRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <v-date-picker
                    :active-picker.sync="activeOverlayDatePicker"
                    locale="de-DE"
                    v-model="appointmentOverlayData.date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col :cols="inputWidth" class="ma-0 pa-0">
                <v-select
                  class="ma-0 pa-0 pt-3 mt-1"
                  :items="categories"
                  v-model="appointmentOverlayData.stage"
                  label="Bühne"
                ></v-select>
              </v-col>
              <v-col :cols="inputWidth" class="ma-0 pa-0">
                <v-menu
                  ref="menu_start"
                  v-model="showStartTimePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-container fluid class="ma-0 pa-0">
                      <v-row
                        style="display: flex; align-items: center"
                        class="ma-0 pa-0"
                      >
                        <v-col cols="2" class="ma-0 pa-0">
                          <v-icon
                            v-on="on"
                            class="pa-0 pr-3"
                            style="width: 30px; height: 30px"
                          >
                            mdi-clock-time-four-outline
                          </v-icon>
                        </v-col>
                        <v-col cols="10" class="ma-0 pa-0">
                          <v-text-field
                            v-model="appointmentOverlayData.time"
                            readonly
                            v-bind="attrs"
                            label="Uhrzeit"
                            v-on="on"
                            required
                            :rules="timeRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <v-time-picker
                    format="24hr"
                    :allowed-minutes="allowedStep"
                    :allowed-hours="allowedHours"
                    :use-seconds="false"
                    v-if="showStartTimePicker"
                    v-model="appointmentOverlayData.time"
                    full-width
                    @change="changeAppointmentStartTime"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col :cols="inputWidth" class="ma-0 pa-0">
                <v-text-field
                  class="ma-0 pa-0 pt-3 mt-1"
                  label="Dauer"
                  v-model="appointmentOverlayData.duration"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-switch label="Ganztägig?" class="ma-0 pa-0"></v-switch>
              </v-col>
            </v-row> -->

            <br />

            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 mb-2 pa-0"> <h3>Kundendaten:</h3> </v-col>
            </v-row>

            <v-row
              v-if="appointmentOverlayData.Vehicle != undefined"
              class="ma-0 pa-0"
            >
              <v-spacer />
              <v-col cols="12" class="pa-0 ma-0">
                <v-card
                  v-if="appointmentOverlayData.Vehicle.Customer"
                  class="ma-0 mb-2 pa-4"
                >
                  <v-btn
                    class="overlayClose rounded-xl"
                    icon
                    @click="removeVehicleFromOverlay()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>

                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="auto" class="pa-0 ma-0">
                        {{
                          (appointmentOverlayData.Vehicle.Customer.company ||
                            "") +
                            " " +
                            (appointmentOverlayData.Vehicle.Customer
                              .first_name || "") +
                            " " +
                            (appointmentOverlayData.Vehicle.Customer
                              .last_name || "")
                        }}<br />
                        <div style="display: flex">
                          <b class="pr-2"
                            >{{ appointmentOverlayData.Vehicle.plate }}
                          </b>
                          {{ appointmentOverlayData.Vehicle.model }}
                        </div>
                      </v-col>
                      <v-spacer />
                      <v-col cols="auto" class="pa-0 ma-0">
                        <div
                          v-if="appointmentOverlayData.Vehicle.need_new_tire"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="red"
                                style="font-size: xxx-large"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <span>Reifen kauptt</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                      <v-col cols="auto" class="pa-0 ma-0">
                        <div v-if="appointmentOverlayData.Vehicle.has_no_rim">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-img
                                style="height: 40px; width: 40px; margin-top: 3px"
                                v-bind="attrs"
                                v-on="on"
                                :src="
                                  require('../../assets/icons/type_keine.svg')
                                "
                              ></v-img>
                            </template>
                            <span>Keine Felge</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>

                <v-card v-else class="ma-0 mb-2 pa-4">
                  <v-btn
                    class="overlayClose rounded-xl"
                    icon
                    @click="removeVehicleFromOverlay()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  {{
                    (appointmentOverlayData.Vehicle.customer_first_name ||
                      "--") +
                      " " +
                      (appointmentOverlayData.Vehicle.customer_last_name ||
                        "--")
                  }}<br />
                  <div style="display: flex">
                    <b class="pr-2"
                      >{{ appointmentOverlayData.Vehicle.plate }}
                    </b>
                    {{ appointmentOverlayData.Vehicle.model }}
                  </div>
                </v-card>
              </v-col>
              <v-spacer />
            </v-row>

            <div v-else>
              <v-row>
                <v-col cols="5" class="ma-0 pa-0">
                  <SearchBar
                    class="removeInputSpacing"
                    :disableCustomerClick="true"
                    @vehicleClick="handleVehicleClick"
                    :class="{
                      requiredSearchBarInput:
                        unvalidated && !appointmentOverlayData.Vehicle,
                    }"
                    @customerCreated="applyCreatedCustomer"
                  />
                </v-col>
                <v-spacer />

                <v-col cols="auto" style="display: flex; align-items: center">
                  <b>oder</b>
                </v-col>

                <v-spacer />

                <v-col cols="auto" class="ma-0 pa-0 pt-3">
                  <v-btn @click="tempVehicleOverlay = true"
                    >Neuer Temp. Kunde</v-btn
                  >
                </v-col>
                <v-spacer />
              </v-row>
            </div>

            <br />

            <v-row class="ma-0 mt-2 pa-0">
              <v-col :cols="iconColSizer" class="ma-0 pa-0">
                <v-container fluid class="ma-0 pa-0">
                  <v-row
                    v-for="(appType, appTypeIndex) in appointmentTypes"
                    :key="appType.id"
                    class="ma-0 pa-0"
                  >
                    <v-col
                      cols="auto"
                      class="ma-0 iconTooltip pa-1"
                      v-for="(subType,
                      subTypeIndex) in appType.appointmentsubtype_set"
                      :key="subType.id"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            v-html="subType.icon"
                            :class="{
                              svgFileIconsEnabled:
                                appointmentOverlayData.appointmentTypes !=
                                  undefined &&
                                appointmentOverlayData.appointmentTypes.indexOf(
                                  subType.id
                                ) >= 0,
                            }"
                            class="svgFileIconsDisabled"
                            @click="
                              adjustAppointmentTypes(appTypeIndex, subTypeIndex)
                            "
                          ></div>
                        </template>
                        <span>{{ subType.name }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col :cols="iconColSizer">
                <v-textarea
                  label="Anmerkung (max. 200 Zeichen)"
                  outlined
                  counter
                  v-model="appointmentOverlayData.annotation"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0" v-if="noAppointmentTypes">
              <v-col class="ma-0 pa-0">
                <p style="color: var(--v-error-base) !important">
                  Auswahl treffen!
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-spacer v-if="buttonSizer < 6 || !appointmentOverlayData.id" />
              <v-col :cols="buttonSizer">
                <v-btn
                  block
                  color="primary"
                  class="font--white"
                  @click="postToDB()"
                  >Termin Buchen</v-btn
                >
              </v-col>
              <v-spacer v-if="appointmentOverlayData.id" />
              <v-col :cols="buttonSizer" v-if="appointmentOverlayData.id">
                <v-btn
                  block
                  color="error"
                  class="font--white"
                  @click="showDeletionWarning = true"
                  ><v-icon>mdi-delete</v-icon>Löschen</v-btn
                >
              </v-col>
              <v-spacer v-if="buttonSizer < 6 || !appointmentOverlayData.id" />
            </v-row>
          </v-container>
        </v-card>
      </v-form>
    </v-overlay>

    <div class="fullScreenOverlay" v-if="tempVehicleOverlay">
      <div class="overlayBackground"></div>
      <NewTempCustomer
        class="overlayDisplay"
        @hide="tempVehicleOverlay = false"
        @created="newTempCustomerCreated"
      />
    </div>

    <div class="fullScreenOverlay" v-if="showDeletionWarning">
      <div class="overlayBackground"></div>
      <WarnBanner
        class="overlayDisplay"
        @cancel="showDeletionWarning = false"
        @approve="deleteFromDB()"
        :text="'diesen Termin'"
        :action="'löschen'"
      />
    </div>

    <div class="fullScreenOverlay" v-if="showRejectionWarning">
      <div class="overlayBackground"></div>
      <WarnBanner
        class="overlayDisplay"
        @cancel="showRejectionWarning = false"
        @approve="denyRequest"
        :buttonTextApprove="'Ja, ablehnen'"
        :action="'ablehnen'"
        :text="'diesen Termin'"
        :additional="
          'Der Kunde wird automatisch per E-Mail über die Ablehnung informiert.'
        "
      />
    </div>

    <div class="fullScreenOverlay" v-if="showAcceptWarning">
      <div class="overlayBackground"></div>
      <WarnBanner
        class="overlayDisplay"
        @cancel="showAcceptWarning = false"
        @approve="acceptRequest"
        :acceptColors="true"
        :buttonTextApprove="'Ja, akzeptieren'"
        :action="'akzeptieren'"
        :text="'diesen Termin'"
        :additional="
          'Der Kunde wird automatisch per E-Mail über die Bestätigung informiert.'
        "
      />
    </div>
    <div class="fullScreenOverlay" v-if="loading">
      <div class="overlayBackground" style="opacity: 0.1"></div>
      <v-progress-circular
        indeterminate
        color="primary"
        class="overlayDisplay"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
const axios = require("axios");

import { getReadableDate } from "../../js/helperFunctions.js";

import SearchBar from "../../components/Header/SearchBar.vue";
import NewTempCustomer from "../../components/Customer/NewTempCustomer.vue";
import WarnBanner from "../../components/Misc/WarnBanner.vue";

export default {
  name: "appointment",

  components: {
    SearchBar,
    NewTempCustomer,
    WarnBanner,
  },

  data() {
    return {
      overlay: false,
      tempVehicleOverlay: false,

      focus: "",
      events: [],
      stages: [],
      appointmentTypes: [],
      colors: ["primary", "accent", "warning"],
      categories: [],
      displayCategories: [],

      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      sendDBUpdate: false,

      appointments: [],
      date_from: undefined,
      date_to: undefined,
      months: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
      displayDate: undefined,

      activeMonthPicker: null,
      activeDatePicker: null,
      activeYearPicker: null,

      activeOverlayDatePicker: null,

      showMonthPicker: false,
      showDatePicker: false,
      showYearPicker: false,

      dateSelectorData: undefined,

      selectedCategory: null,

      appointmentOverlayData: {},

      showStartTimePicker: false,
      showEndTimePicker: false,

      allowedStep: (m) => m % 15 === 0,
      allowedHours: (v) => v > 8 && v < 18,

      //rules
      unvalidated: false,
      noAppointmentTypes: false,
      dateRules: [
        (v) => (!!v && v.split(".").length == 3) || "Gültiges Datum angeben!",
      ],
      timeRules: [
        (v) => (!!v && v.split(":").length == 2) || "Gültige Zeit angeben!",
      ],

      showDeletionWarning: false,
      showRejectionWarning: false,
      showAcceptWarning: false,
      loading: false,

      activeAppointment: undefined,
    };
  },

  computed: {
    singleLineHeader() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
        default:
          return false;
      }
    },
    colLimit() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 12;
        case "lg":
          return 10;
        case "xl":
          return 8;
        default:
          return 8;
      }
    },
    inputWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 5;
        case "lg":
          return 5;
        case "xl":
          return 5;
        default:
          return 8;
      }
    },
    buttonSizer() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 6;
        case "sm":
          return 6;
        case "md":
          return 3;
        case "lg":
          return 3;
        case "xl":
          return 3;
        default:
          return 8;
      }
    },
    iconColSizer() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 12;
        case "lg":
          return 6;
        case "xl":
          return 6;
        default:
          return 8;
      }
    },
  },

  watch: {
    showDatePicker(val) {
      val && setTimeout(() => (this.activeDatePicker = "DATE"));
    },
    showMonthPicker(val) {
      val && setTimeout(() => (this.activeMonthPicker = "MONTH"));
    },
    showYearPicker(val) {
      val && setTimeout(() => (this.activeYearPicker = "YEAR"));
    },
    dateSelectorData(val) {
      if (val) {
        this.showDatePicker = false;
        this.showMonthPicker = false;
        this.showYearPicker = false;

        this.focus = val;
        this.fetchEvents();

        val = undefined;
      }
    },
  },

  mounted() {
    if (this.$route.params.datum) {
      this.focus = this.$route.params.datum;
    }

    axios
      .get(axios.defaults.baseURL + "stages/by/location/?location=1")
      .then((resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          this.categories.push(resp.data[i].name);

          if (localStorage.getItem("stages")) {
            if (
              localStorage
                .getItem("stages")
                .split(",")
                .indexOf(resp.data[i].name) >= 0
            ) {
              this.displayCategories.push(resp.data[i].name);
            }
          }
        }

        this.stages = resp.data;
        console.log(this.stages)
        this.fetchEvents();
      })
      .catch((e) => console.log(e));

    axios
      .get(axios.defaults.baseURL + "appointmenttypes")
      .then((resp) => {
        this.appointmentTypes = resp.data;
        for (let i = 0; i < this.appointmentTypes.length; i++) {
          var rawFile = new XMLHttpRequest();
          rawFile.open("GET", this.appointmentTypes[i].icon, false);
          rawFile.send(null);

          this.appointmentTypes[i].icon = rawFile.responseText;

          for (
            let j = 0;
            j < this.appointmentTypes[i].appointmentsubtype_set.length;
            j++
          ) {
            var rawFile2 = new XMLHttpRequest();
            rawFile2.open(
              "GET",
              this.appointmentTypes[i].appointmentsubtype_set[j].icon,
              false
            );
            rawFile2.send(null);

            this.appointmentTypes[i].appointmentsubtype_set[j].icon =
              rawFile2.responseText;
          }
        }
      })
      .catch((e) => console.log(e));

    let backupAppointmentOverlay = this.$store.state.backupAppointmentOverlay;
    if (
      backupAppointmentOverlay &&
      Date.now() - backupAppointmentOverlay.timestamp < 300000
    ) {
      this.appointmentOverlayData = backupAppointmentOverlay.data;

      let vehicleID = this.$store.state.appointmentVehicleID;
      if (vehicleID && Date.now() - vehicleID.timestamp <= 300000) {
        axios
          .get(axios.defaults.baseURL + "vehicles/" + vehicleID.vehicleID)
          .then((resp) => {
            this.appointmentOverlayData.Vehicle = resp.data;
            this.$forceUpdate();
          });
      }

      this.$store.state.appointmentVehicleID = undefined;

      this.overlay = true;
    }
    this.$store.state.backupAppointmentOverlay = undefined;
  },

  methods: {
    getWeekday(dayNumber) {
      switch (dayNumber) {
        case 0:
          return "So";
        case 1:
          return "Mo";
        case 2:
          return "Di";
        case 3:
          return "Mi";
        case 4:
          return "Do";
        case 5:
          return "Fr";
        default:
          return "Sa";
      }
    },
    checkMainTypeEnabled(appTypeIndex) {
      if (
        !this.appointmentOverlayData ||
        !this.appointmentOverlayData.appointmentTypes
      )
        return false;

      for (
        let i = 0;
        i < this.appointmentTypes[appTypeIndex].appointmentsubtype_set.length;
        i++
      ) {
        if (
          this.appointmentOverlayData.appointmentTypes.indexOf(
            this.appointmentTypes[appTypeIndex].appointmentsubtype_set[i].id
          ) >= 0
        ) {
          return true;
        }
      }

      return false;
    },
    calcDuration() {
      if (!this.appointmentOverlayData) return;
      if (!this.appointmentOverlayData.appointmentTypes) {
        this.appointmentOverlayData.duration = 0;
        return;
      }

      this.appointmentOverlayData.duration = 0;
      for (
        let appTypeIndex = 0;
        appTypeIndex < this.appointmentTypes.length;
        appTypeIndex++
      ) {
        for (
          let i = 0;
          i < this.appointmentTypes[appTypeIndex].appointmentsubtype_set.length;
          i++
        ) {
          if (
            this.appointmentOverlayData.appointmentTypes.indexOf(
              this.appointmentTypes[appTypeIndex].appointmentsubtype_set[i].id
            ) >= 0
          ) {
            this.appointmentOverlayData.duration += this.appointmentTypes[
              appTypeIndex
            ].duration;
            break;
          }
        }
      }
    },
    deleteFromDB() {
      for (let i = this.events.length - 1; i >= 0; i--) {
        if (this.events[i].id == this.appointmentOverlayData.id) {
          if (this.events[i].url == "appointments") {
            axios
              .delete(
                axios.defaults.baseURL +
                  "appointments/" +
                  this.appointmentOverlayData.id
              )
              .then(() => {
                this.showDeletionWarning = false;

                this.events.splice(i, 1);

                this.closeOverlay();
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            axios
              .delete(
                axios.defaults.baseURL +
                  "tempAppointments/" +
                  this.appointmentOverlayData.id
              )
              .then(() => {
                this.showDeletionWarning = false;

                this.events.splice(i, 1);

                this.closeOverlay();
              })
              .catch((e) => {
                console.log(e);
              });
          }
          break;
        }
      }
    },
    newTempCustomerCreated(data) {
      this.tempVehicleOverlay = false;
      this.appointmentOverlayData.Vehicle = data;
    },
    applyCreatedCustomer(data) {
      this.$store.state.backupAppointmentOverlay = {
        data: this.appointmentOverlayData,
        timestamp: Date.now(),
      };

      this.$router.push("/kunden/" + data.id);
    },
    getReadableDate: getReadableDate,
    gotoMonths() {
      this.$router.push("/uebersicht");
    },
    postToDB() {
      if (this.appointmentOverlayData.Vehicle == undefined) {
        this.unvalidated = true;
      }

      if (
        this.appointmentOverlayData.appointmentTypes == undefined ||
        this.appointmentOverlayData.appointmentTypes.length == 0
      ) {
        this.noAppointmentTypes = true;
      }

      if (
        this.$refs.appForm.validate() &&
        !this.unvalidated &&
        !this.noAppointmentTypes
      ) {
        let stage;
        for (let i = 0; i < this.stages.length; i++) {
          if (this.stages[i].name == this.appointmentOverlayData.stage) {
            stage = this.stages[i].id;
          }
        }

        if (!stage) return;

        let formData = {
          date: this.appointmentOverlayData.date,
          time: this.appointmentOverlayData.time,
          duration: this.appointmentOverlayData.duration,
          SubType: this.appointmentOverlayData.appointmentTypes,
          User: localStorage.getItem("id"),
          Vehicle: this.appointmentOverlayData.Vehicle.id,
          Stage: stage,
          annotation: this.appointmentOverlayData.annotation,
          state: 1
        };

        console.log("fd", formData);

        if (this.appointmentOverlayData.id != undefined) {
          //temp appointment
          if (this.appointmentOverlayData.Vehicle.Customer == undefined) {
            if (this.appointmentOverlayData.type == "app") {
              axios
                .post(axios.defaults.baseURL + "tempAppointments/new", formData)
                .then(() => {
                  axios
                    .delete(
                      axios.defaults.baseURL +
                        "appointments/" +
                        this.appointmentOverlayData.id
                    )
                    .then(() => {
                      this.fetchEvents();

                      this.closeOverlay();
                    })
                    .catch((e2) => console.log(e2));
                })
                .catch((e) => console.log(e));
            } else {
              formData.id = this.appointmentOverlayData.id;

              axios
                .patch(
                  axios.defaults.baseURL +
                    "tempAppointments/patch/" +
                    formData.id,
                  formData
                )
                .then((resp) => {
                  console.log(resp);
                  this.fetchEvents();

                  this.closeOverlay();
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          } else {
            //actual appointment
            if (this.appointmentOverlayData.type == "app") {
              formData.id = this.appointmentOverlayData.id;
              axios
                .patch(
                  axios.defaults.baseURL + "appointments/patch/" + formData.id,
                  formData
                )
                .then(() => {
                  this.fetchEvents();

                  this.closeOverlay();
                })
                .catch((e) => console.log(e));
            } else {
              axios
                .post(axios.defaults.baseURL + "appointments/new", formData)
                .then(() => {
                  axios
                    .delete(
                      axios.defaults.baseURL +
                        "tempAppointments/" +
                        this.appointmentOverlayData.id
                    )
                    .then(() => {
                      this.fetchEvents();

                      this.closeOverlay();
                    })
                    .catch((e2) => console.log(e2));
                })
                .catch((e) => console.log(e));
            }
          }
        } else {
          //temp appointment
          if (this.appointmentOverlayData.Vehicle.Customer == undefined) {
            axios
              .post(axios.defaults.baseURL + "tempAppointments/new", formData)
              .then(() => {
                this.fetchEvents();

                this.closeOverlay();
              })
              .catch((e) => console.log(e));
          } else {
            //actual appointment
            axios
              .post(axios.defaults.baseURL + "appointments/new", formData)
              .then(() => {
                this.fetchEvents();

                this.closeOverlay();
              })
              .catch((e) => console.log(e));
          }
        }
      }
    },
    handleVehicleClick(data) {
      this.unvalidated = false;

      if (!data.vehicleID) {
        this.$store.state.backupAppointmentOverlay = {
          data: this.appointmentOverlayData,
          timestamp: Date.now(),
        };

        this.$router.push("/kunden/" + data.item.customer.id + "/neu");
      } else {
        for (let i = 0; i < data.item.vehicles.length; i++) {
          if (data.item.vehicles[i].id == data.vehicleID) {
            this.appointmentOverlayData.Vehicle = {
              id: data.item.vehicles[i].id,
              plate: data.item.vehicles[i].plate,
              model: data.item.vehicles[i].model,
              need_new_tire: data.item.vehicles[i].need_new_tire,
              has_no_rim: data.item.vehicles[i].has_no_rim,
            };

            if (this.appointmentOverlayData.Vehicle.has_no_rim) {
              this.noAppointmentTypes = false;

              if (!this.appointmentOverlayData.appointmentTypes) {
                this.appointmentOverlayData.appointmentTypes = [];
              }

              //sloppy
              if (this.appointmentOverlayData.appointmentTypes.indexOf(5) < 0) {
                this.appointmentOverlayData.appointmentTypes.push(5);
              }

              this.$forceUpdate();

              this.calcDuration();
            }
          }
        }

        this.appointmentOverlayData.Vehicle.Customer = {
          first_name: data.item.customer.firstName,
          last_name: data.item.customer.lastName,
          company: data.item.customer.company,
          id: data.item.customer.id,
        };

        this.$forceUpdate();
      }
    },
    removeVehicleFromOverlay() {
      this.appointmentOverlayData.Vehicle = null;
      this.$forceUpdate();
    },
    adjustAppointmentTypes(appIndex, subTypeIndex) {
      this.noAppointmentTypes = false;

      if (!this.appointmentOverlayData.appointmentTypes) {
        this.appointmentOverlayData.appointmentTypes = [];
      }

      if (
        this.appointmentOverlayData.appointmentTypes.indexOf(
          this.appointmentTypes[appIndex].appointmentsubtype_set[subTypeIndex]
            .id
        ) < 0
      ) {
        this.appointmentOverlayData.appointmentTypes.push(
          this.appointmentTypes[appIndex].appointmentsubtype_set[subTypeIndex]
            .id
        );
      } else {
        this.appointmentOverlayData.appointmentTypes.splice(
          this.appointmentOverlayData.appointmentTypes.indexOf(
            this.appointmentTypes[appIndex].appointmentsubtype_set[subTypeIndex]
              .id
          ),
          1
        );
      }

      this.$forceUpdate();

      this.checkMainTypeEnabled(appIndex);
      this.calcDuration();
    },
    closeOverlay() {
      this.overlay = !this.overlay;

      for (let i = this.events.length - 1; i >= 0; i--) {
        if (this.events[i].name == "Neuer Termin") {
          this.events.splice(i, 1);
        }
      }

      this.appointmentOverlayData = {};
    },
    changeAppointmentStartTime(val) {
      let newVal = val.split(":");
      this.appointmentOverlayData.from = newVal[0] + ":" + newVal[1];
      this.showStartTimePicker = false;
    },
    selectCategory({ category }) {
      if (category) this.selectedCategory = category.categoryName;
    },
    deselectCategory() {
      this.selectedCategory = null;
    },
    switchStageDisplay(val) {
      this.displayCategories = [];
      for (let i = 0; i < val.length; i++) {
        this.displayCategories.push(val[i]);
      }
      localStorage.setItem("stages", this.displayCategories.toString());
    },
    startDrag({ event, timed }) {
      console.log(event)
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          name: "Neuer Termin",
          color: this.colors[0],
          start: this.createStart,
          end: this.createStart,
          timed: true,
          category: this.selectedCategory,
          customer: "---",
          vip: false,
          annotation: undefined,
        };

        this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;

        if (this.selectedCategory != this.dragEvent.category) {
          this.dragEvent.category = this.selectedCategory;
        }

        this.sendDBUpdate = true;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    patchAppointmentData(elem) {
      this.sendDBUpdate = false;
      this.overlay = false;

      let data = {
        id: elem.id,
        time: new Date(elem.start).toLocaleString().split(", ")[1],
      };

      let stage;
      for (let i = 0; i < this.stages.length; i++) {
        if (this.stages[i].name == elem.category) {
          data.Stage = this.stages[i].id;
          stage = this.stages[i];
        }
      }

      axios
        .patch(axios.defaults.baseURL + elem.url + "/patch/" + elem.id, data)
        .then((resp) => {
          if (elem.url == "appointments") {
            for (let i = 0; i < this.appointments.appointments.length; i++) {
              if (this.appointments.appointments[i].id == elem.id) {
                this.appointments.appointments[i].Stage = stage;
                this.appointments.appointments[i].time = resp.data.time;
              }
            }
          } else {
            for (
              let i = 0;
              i < this.appointments.temp_appointments.length;
              i++
            ) {
              if (this.appointments.temp_appointments[i].id == elem.id) {
                this.appointments.temp_appointments[i].Stage = stage;
                this.appointments.temp_appointments[i].time = resp.data.time;
              }
            }
          }

          this.overlay = false;
        })
        .catch((e) => console.log(e));
    },
    endDrag() {
      if (this.createEvent) {
        let vehicleID = this.$store.state.appointmentVehicleID;
        if (vehicleID && Date.now() - vehicleID.timestamp <= 300000) {
          axios
            .get(axios.defaults.baseURL + "vehicles/" + vehicleID.vehicleID)
            .then((resp) => {
              this.appointmentOverlayData.Vehicle = resp.data;
              this.$forceUpdate();
            });
        }

        this.$store.state.appointmentVehicleID = undefined;

        let date = new Date(this.createEvent.start);
        let startStamp = date
          .toLocaleString()
          .split(", ")[1]
          .split(":");
        this.appointmentOverlayData.time = startStamp[0] + ":" + startStamp[1];

        this.appointmentOverlayData.date =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate();

        this.appointmentOverlayData.stage = this.createEvent.category;

        this.dragTime = null;
        this.dragEvent = null;
        this.createEvent = null;
        this.createStart = null;
        this.extendOriginal = null;

        this.overlay = true;
      } else {
        if (this.dragEvent && this.sendDBUpdate) {
          this.patchAppointmentData(this.dragEvent);
        }

        this.overlay = false;

        this.dragTime = null;
        this.dragEvent = null;
        this.createEvent = null;
        this.createStart = null;
        this.extendOriginal = null;
      }
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          console.log(this.createEvent)
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    selectEvent(elem) {
      if (this.sendDBUpdate) {
        this.pathAppointmentData(elem.event);
      } else {
        if (elem.event.url == "appointments") {
          for (let i = 0; i < this.appointments.appointments.length; i++) {
            if (this.appointments.appointments[i].id == elem.event.id) {
              this.appointmentOverlayData = this.appointments.appointments[i];

              this.appointmentOverlayData.type = "app"; //save type for type change

              let timeStringArray = this.appointmentOverlayData.time.split(":");
              if (timeStringArray.length) {
                this.appointmentOverlayData.time =
                  timeStringArray[0] + ":" + timeStringArray[1];
              }

              this.appointmentOverlayData.appointmentTypes = [];
              for (
                let j = 0;
                j < this.appointments.appointments[i].SubType.length;
                j++
              ) {
                this.appointmentOverlayData.appointmentTypes.push(
                  this.appointments.appointments[i].SubType[j].id
                );
              }

              break;
            }
          }
        } else {
          for (let i = 0; i < this.appointments.temp_appointments.length; i++) {
            if (this.appointments.temp_appointments[i].id == elem.event.id) {
              this.appointmentOverlayData = this.appointments.temp_appointments[
                i
              ];

              this.appointmentOverlayData.type = "temp"; //save type for type change

              let timeStringArray = this.appointmentOverlayData.time.split(":");
              if (timeStringArray.length) {
                this.appointmentOverlayData.time =
                  timeStringArray[0] + ":" + timeStringArray[1];
              }

              this.appointmentOverlayData.appointmentTypes = [];
              for (
                let j = 0;
                j < this.appointments.temp_appointments[i].SubType.length;
                j++
              ) {
                this.appointmentOverlayData.appointmentTypes.push(
                  this.appointments.temp_appointments[i].SubType[j].id
                );
              }

              break;
            }
          }
        }

        this.appointmentOverlayData.stage = this.appointmentOverlayData.Stage.name;

        this.overlay = true;

        console.log("overlay", this.appointmentOverlayData);
      }

      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    formatDateString(val) {
      return val.time;
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
      this.fetchEvents();
    },
    setToDate(date) {
      this.focus = date;
      this.fetchEvents();
    },
    prev() {
      this.$refs.calendar.prev();
      this.fetchEvents();
    },
    next() {
      this.$refs.calendar.next();
      this.fetchEvents();
    },
    fetchEvents() {
      if (this.focus) {
        let temp = this.focus.split("-");
        this.date_from = this.date_to = temp[2] + "." + temp[1] + "." + temp[0];
        console.log("if", this.date_from);
      } else {
        this.date_from = this.date_to = new Date(Date.now())
          .toLocaleString()
          .split(",")[0];
        console.log("else");
      }

      let eventElems = document
        .getElementById("termine")
        .getElementsByClassName("v-event-timed");
      for (let i = eventElems.length - 1; i >= 0; i--) {
        eventElems[i].parentNode.removeChild(eventElems[i]);
      }

      this.events = [];
      this.appointments = [];

      axios
        .get(
          axios.defaults.baseURL +
            "appointments/by/date/?start=" +
            this.date_from +
            "&end=" +
            this.date_to +
            "&location=1"
        )
        .then((resp) => {
          console.log(resp.data);
          this.events = [];
          this.appointments = [];

          this.appointments = resp.data;

          this.fillAppointmentsIntoEvents(resp.data.appointments, "app");
          this.fillAppointmentsIntoEvents(resp.data.temp_appointments, "temp");

          this.$refs.calendar.checkChange();
        });
    },
    fillAppointmentsIntoEvents(array, prefix) {
      for (let i = 0; i < array.length; i++) {
        let first = new Date(`${array[i].date}T${array[i].time}`);
        let second = new Date(`${array[i].date}T${array[i].time}`);

        first = first.getTime();
        second = second.getTime() + array[i].duration * 60000;

        let icons = [];

        for (let j = 0; j < array[i].SubType.length; j++) {
          var rawFile = new XMLHttpRequest();
          rawFile.open("GET", array[i].SubType[j].icon, false);
          rawFile.send(null);

          icons.push(rawFile.responseText);
        }

        this.events.push({
          name: array[i].Vehicle.plate,
          start: first,
          end: second,
          color:
            array[i].state == 0
              ? "#eb6e2a"
              : prefix == "app"
              ? this.colors[0]
              : this.colors[1],
          timed: true,
          category: this.categories[
            this.categories.indexOf(array[i].Stage.name)
          ],
          customer: array[i].Vehicle.Customer
            ? (array[i].Vehicle.Customer.company || "") +
              " " +
              (array[i].Vehicle.Customer.last_name || "")
            : (array[i].Vehicle.customer_first_name || "") +
              " " +
              (array[i].Vehicle.customer_last_name || ""),
          id: array[i].id,
          status: array[i].state,
          url: prefix == "app" ? "appointments" : "tempAppointments",
          icons: icons,
          vip: array[i].Vehicle.Customer && array[i].Vehicle.Customer.vip,
          annotation: array[i].annotation,
        });
      }
    },
    convertToUseableTimeString(val) {
      let result = val.split(",")[0];
      result = result.split(".");
      result[2] = "20" + result[2];

      result = result[0] + "." + result[1] + "." + result[2];

      return result;
    },
    acceptRequest() {
      this.loading = true;
      axios
        .patch(
          axios.defaults.baseURL +
            "appointments/patch/" +
            this.activeAppointment.id,
          {
            id: this.activeAppointment.id,
            state: 1,
          }
        )
        .then(() => {
          this.fetchEvents();
          this.showAcceptWarning = false;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.showAcceptWarning = false;
          this.loading = false;
        });
    },
    denyRequest() {
      this.loading = true;

      axios
        .delete(
          axios.defaults.baseURL +
            "appointments/patch/" +
            this.activeAppointment.id
        )
        .then(() => {
          // console.log(resp.data);
          for (let i = 0; i < this.events.length; i++) {
            if (this.events[i].id == this.activeAppointment.id) {
              this.events.splice(i, 1);
              break;
            }
          }
          this.showRejectionWarning = false;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.showRejectionWarning = false;
          this.loading = false;
        });
    },
    rejectionWarning(event, appointment) {
      event.stopPropagation();
      event.preventDefault();

      this.showRejectionWarning = true;
      this.activeAppointment = appointment;
    },
    acceptWarning(event, appointment) {
      event.stopPropagation();
      event.preventDefault();

      this.showAcceptWarning = true;
      this.activeAppointment = appointment;
    },
  },

  beforeUnmount() {
    this.$store.state.appointmentVehicleID = undefined;
  },
};
</script>
