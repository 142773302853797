<template>
  <div id="appointmentLogin">
    <v-app>
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-spacer />
          <v-col :cols="mainCols">
            <v-card class="pa-6">
              <v-container>
                <v-row align="center" justify="center">
                  <v-col cols="12">
                    <v-img
                      src="../../assets/images/ReifenReiboldLogo.png"
                      height="100px"
                      style="max-height: 80px"
                    ></v-img>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col :cols="mainCols">
                    <h2 class="mt-4">Login</h2>
                  </v-col>
                </v-row>
                
                <v-form v-model="isValid" lazy-validation>
                  <v-row justify="center">
                    <v-col :cols="mainCols">
                      <v-text-field
                        filled
                        v-model="email"
                        hint="E-Mail-Adresse*"
                        placeholder="max.mustermann@email.de"
                        persistent-hint
                        :rules="validMailRule"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col :cols="mainCols">
                      <v-text-field
                        filled
                        v-model="plate"
                        hint="Kennzeichen* (KS-AB 1234)"
                        placeholder="ABC-DE 1234"
                        persistent-hint
                        :rules="requiredRule"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col :cols="mainCols">
                      <v-btn :disabled="!isValid" class="basicButton" @click="login">Login</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
      <v-snackbar
      v-model="snackbar"
      :multi-line="true"
      top
      color="primary"
      class="text-center"
      timeout="-1"
    >
      <h3>Fehlende Berechtigung.</h3> <br />
      Wenn Sie dies für einen Fehler halten, melden Sie sich gerne
      telefonisch bei uns unter (0561) 9415600.

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
    </v-app>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: undefined,
      plate: undefined,
      snackbar: false,

      validMailRule: [
        (v) => !!v || "Bitte eine E-Mail-Adresse eingeben",
        (v) => /.+@.+\..+/.test(v) || "Keine korrekte E-Mail-Adresse!",
      ],
      requiredRule: [(v) => !!v || "Bitte ein Kennzeichen eingeben"],
    };
  },
  computed: {
    mainCols() {
      switch(this.$vuetify.breakpoint.name){
        case 'xs':
          return 12
        case 'sm':
          return 10
        case 'md':
          return 8
        case 'lg':
          return 8
        default:
          return 6
      }
    }
  },

  methods: {
    login() {
      let dataObject = {
        email: this.email,
        plate: this.plate
      }
      axios.post(axios.defaults.baseURL + 'appointments/auth/', dataObject)
      .then((resp) => {
        console.log(resp.data)
        this.$emit("login", {email: this.email, plate: this.plate})
      })
      .catch((e) => {
        this.snackbar = true;
        console.log(e)
      })
    },
  },
};
</script>
