<template>
  <v-card
    height="100%"
    class="px-4 py-4"
    style="background-color: var(--v-background-base); min-width: 200px; color: var(--v-primary-base)"
  >
    <v-container v-if="appointmentData" fluid fill-height style="min-height: 300px" :style="{ opacity: this.appointmentData.start_date < todaysDate ? '0.5' : '1'}">
      <v-row align="center">
        <v-col>
          <b>{{ scheduleDays.join(", ") }}</b>
        </v-col>
        <v-col cols="auto">
          <v-btn icon style="background-color: var(--v-primary-base);" @click="editAppointment">
            <v-icon color="background">mdi-pencil</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn icon style="background-color: var(--v-primary-base);" @click="deleteWarning">
            <v-icon color="background">mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col>
          <h2>
            {{ appointmentData.start_time.split(":")[0] }}:{{
              appointmentData.start_time.split(":")[1]
            }}
            - {{ appointmentData.end_time.split(":")[0] }}:{{
              appointmentData.end_time.split(":")[1]
            }}
          </h2>
        </v-col>
      </v-row>
      <v-row class="mt-1">
        <v-col>
          <b
            >Startdatum: {{ getReadableDate(appointmentData.start_date) }}<br />
            Enddatum: {{ getReadableDate(appointmentData.end_date) }}</b
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <b
            >Bühnen:
            <span v-for="(stage, index) in scheduleStages" :key="stage.id">
              {{ stage.name }}<span v-if="index != scheduleStages.length - 1">,</span>
            </span></b
          >
        </v-col>
      </v-row>
      <!-- <v-row class="mt-6">
        <v-col>
          <v-switch
            color="primary"
            class="appointmentSlotSwitch"
            v-model="active"
            label="aktiv?"
            @change="changeScheduleActiveState"
          ></v-switch>
        </v-col>
      </v-row> -->
    </v-container>
    <v-container fluid fill-height v-else style="min-height: 300px">
      <v-row>
        <v-spacer />
        <v-col class="text-center">
          <div @click="createTimeslot">
            <v-icon
              x-large
              style="
                background-color: var(--v-primary-base);
                width: 60px;
                height: 60px;
                border-radius: 50%;
              "
              color="background"
              >mdi-plus</v-icon
            >
          </div>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
    <div class="fullScreenOverlay" v-if="loading">
      <div class="overlayBackground" style="opacity: 0.1"></div>
      <v-progress-circular
        indeterminate
        color="primary"
        class="overlayDisplay"
      ></v-progress-circular>
    </div>

    <div class="fullScreenOverlay" v-if="showDeleteWarning">
      <div class="overlayBackground"></div>
      <WarnBanner
        class="overlayDisplay"
        @cancel="showDeleteWarning = false"
        @approve="deleteSchedule"
        :action="'löschen'"
        :text="'diesen Zeitplan'"
        :additional="'Alle Termine ohne offene Anfragen werden somit auch gelöscht!'"
      />
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import { getReadableDate } from "../../js/helperFunctions.js";
import WarnBanner from "@/components/Misc/WarnBanner.vue";

export default {
  props: ["appointmentData"],

  data() {
    return {
      active: undefined,
      loading: false,

      showDeleteWarning: false,
    };
  },
  components: {
    WarnBanner,
  },
  computed: {
    scheduleStages() {
      return this.appointmentData.Stage.map(function (value) {
        return { id: value.id, name: value.name };
      });
    },
    scheduleDays() {
      let days = [];
      if (this.appointmentData.is_monday) days.push("Mo");
      if (this.appointmentData.is_tuesday) days.push("Di");
      if (this.appointmentData.is_wednesday) days.push("Mi");
      if (this.appointmentData.is_thursday) days.push("Do");
      if (this.appointmentData.is_friday) days.push("Fr");
      if (this.appointmentData.is_saturday) days.push("Sa");
      if (this.appointmentData.is_sunday) days.push("So");

      return days;
    },
    todaysDate() {
      return new Date().toISOString().split('T')[0]
    }
  },
  methods: {
    editAppointment() {
      this.$router.replace("zeitplan/" + this.appointmentData.id);
    },
    createTimeslot() {
      this.$router.replace("zeitplan/new");
    },
    changeScheduleActiveState() {
      this.loading = true;

      axios
        .patch(
          axios.defaults.baseURL +
            "appointments/schedules/patch/" +
            this.appointmentData.id,
          {
            id: this.appointmentData.id,
            is_active: this.active,
          }
        )
        .then((resp) => {
          console.log(resp.data);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteSchedule() {
      axios
        .delete(
          axios.defaults.baseURL +
            "appointments/schedules/delete/" +
            this.appointmentData.id
        )
        .then((resp) => {
          console.log(resp.data);
          this.showDeleteWarning = false;
          this.$emit("refresh");
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteWarning(){
      this.showDeleteWarning = true;
    },
    getReadableDate: getReadableDate,
  },
  mounted() {
    this.active = this.appointmentData?.is_active;
  },
};
</script>
