var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-2 pa-0",staticStyle:{"min-height":"230px"},style:({ opacity: _vm.opacity }),on:{"click":_vm.gotoVehicle}},[_c('v-card-title',[_c('v-chip',[_vm._v(_vm._s(_vm.appointment.Stage.name))]),_c('v-spacer'),_c('b',[_vm._v(_vm._s(_vm.formattedTime(_vm.appointment.time))+" ")]),(_vm.appointment.Vehicle.Customer && _vm.appointment.Vehicle.Customer.vip)?_c('v-icon',{staticClass:"pl-2",attrs:{"color":"secondary"}},[_vm._v("mdi-star")]):_vm._e(),_c('v-spacer'),_c('span',[_vm._v(_vm._s(_vm.appointment.duration)+" Min")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-1"},[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"9"}},[_c('h3',[_vm._v(_vm._s(_vm.appointment.Vehicle.plate))]),(
              _vm.appointment.Vehicle.tire_set && _vm.appointment.Vehicle.tire_set.length > 0
            )?_c('div',_vm._l((_vm.appointment.Vehicle.tire_set),function(tire){return _c('div',{key:tire.id},[(tire.in_storage)?_c('h3',[_vm._v(_vm._s(tire.storage_number))]):_vm._e()])}),0):_c('div',[_c('h3',[_vm._v("Nicht eingelagert")])]),(_vm.appointment.Vehicle.Customer)?_c('span',{staticStyle:{"white-space":"nowrap","text-overflow":"ellipsis","width":"90%","display":"block","overflow":"hidden"}},[_vm._v(_vm._s((_vm.appointment.Vehicle.Customer.first_name || "") + " " + (_vm.appointment.Vehicle.Customer.last_name || "")))]):(
              _vm.appointment.Vehicle.customer_first_name ||
              _vm.appointment.Vehicle.customer_last_name
            )?_c('span',{staticStyle:{"white-space":"nowrap","text-overflow":"ellipsis","width":"90%","display":"block","overflow":"hidden"}},[_vm._v(_vm._s((_vm.appointment.Vehicle.customer_first_name || " ") + " " + (_vm.appointment.Vehicle.customer_last_name || " ")))]):_c('span',[_vm._v("-Kein Name-")])]),_c('v-col',{staticClass:"px-0",staticStyle:{"display":"flex","align-items":"center"},attrs:{"cols":"3"}},[(_vm.isTireAppointment && _vm.appointment.state == 0)?_c('v-btn',{staticClass:"px-2",staticStyle:{"min-width":"unset"},attrs:{"color":"primary"},on:{"click":_vm.switchStorage}},[_c('v-img',{attrs:{"src":require('../../assets/icons/switchStorage.svg')}})],1):(_vm.isTireAppointment && _vm.appointment.state == 1)?_c('v-btn',{staticClass:"px-2",staticStyle:{"min-width":"unset"},attrs:{"color":"primary"},on:{"click":_vm.printLabels}},[_c('v-img',{attrs:{"src":require('../../assets/icons/labelPDF.svg')}})],1):_vm._e()],1)],1),_c('v-row',_vm._l((_vm.appointment.SubType),function(type){return _c('v-col',{key:type.id,attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"svgIconSizer",class:{
                  svgFileIconsEnabledSecondary: !_vm.appointment.Vehicle.Customer,
                  svgFileIconsEnabled: _vm.appointment.Vehicle.Customer,
                },domProps:{"innerHTML":_vm._s(_vm.icons[type.id])}},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(type.name))])])],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }